export default {
    open: 'Du öffnest',
    close: 'Schließen',
    apply: 'Anwenden',
    cancel: 'Stornieren',
    search: 'Nahe',
    cancel_search: 'Suche abbrechen',
    edit_search: 'Suche bearbeiten',
    erase: 'Stornieren',
    back_to_results: 'Gehen Sie zurück zu den Ergebnissen',
    save: 'Speichern',
    retry: 'Versuchen Sie es erneut',
    click_here: 'klicken Sie hier',
    copy: 'Kopieren',
    copied: 'Kopiert',
    copied_coordinates: 'Koordinaten kopiert',
    select: 'Wählen',
    selected: 'Ausgewählt',
    selected_plural: 'Ausgewählt',
    deselect: 'Abwählen',
    select_multi_vehicles: 'Wählen Sie ein oder mehrere Fahrzeuge aus',
    include: 'Enthalten',
    exclude: 'Ausschließen',
    balanced: 'Ausgewogen',
    input: 'Eingang',
    output: 'Ausfahrt',
    verified: 'Verifiziert',
    empty_search: 'keine Ergebnisse gefunden',
    read_more: 'Mehr lesen',
    details: 'Einzelheiten',
    actual: 'Aktuell',
    residual_time: 'Verbleibende Zeit',
    next: 'Nächste',
    previous: 'Vorherige',
    menu_open: 'Menü öffnen',
    hide: 'Verstecken',
    show: 'Zeigen',
    none: 'Niemand',
    none_alt: 'Keiner',
    all: 'Alle',
    all_alt: 'Alle',
    default: 'Standard',
    settings: 'Einstellungen',
    reset_settings: 'Einstellungen zurücksetzen',
    reset_settings_success: 'Einstellungen erfolgreich zurückgesetzt',
    multiselect_default_value: {
        singular: 'Ausgewählter Wert:',
        plural: 'Ausgewählte Werte:'
    },
    available: 'Verfügbar',
    unavailable: 'Nicht verfügbar',
    not_available: 'Nicht verfügbar',
    not_available_plural: 'Nicht verfügbar',
    from: 'von dem',
    to: 'zum',
    of: 'Von',
    yes: 'Ja',
    no: 'NEIN',
    minutes: 'Protokoll',
    hours: 'Std',
    hour: 'Jetzt',
    days: 'Tage',
    day: 'Tag',
    months: 'Monate',
    month: 'Monat',
    years: 'Jahre',
    year: 'Jahr',
    days_names: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    days_names_short: ['Sonne', 'Mo', 'Beschädigen', 'Heiraten', 'Do', 'Fr', 'Sa'],
    months_names: [
        'Januar',
        'Februar',
        'Marsch',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
    ],
    months_names_short: [
        'Gen',
        'Febr',
        'Beschädigen',
        'Apr',
        'Mag',
        'Unten',
        'Juli',
        'Aug',
        'Satz',
        'Okt',
        'Nov',
        'Dez'
    ],
    areas: 'Bereiche',
    area: 'Bereich',
    time: 'Zeit (Min.)',
    time_label: 'Zeit',
    cost: 'Kosten',
    toll_cost: 'Mautkosten',
    km_cost: 'Kosten pro km',
    hourly_cost: 'Stündliche Kosten',
    working_cost: 'Gesamtkosten pro Mann',
    total_cost: 'Gesamtkosten',
    expire_date: 'Verfallsdatum',
    start_date: 'Startdatum',
    recap: 'Zusammenfassung',
    start: 'Start',
    starting_point: 'Abfahrt',
    end: 'Ende',
    finish_point: 'ich komme an',
    duration: 'Dauer',
    distance: 'Distanz',
    daily_distance: 'Täglich zurückgelegte Kilometer',
    distance_total: 'Gesamtentfernung',
    gps_distance: 'Entfernung (GPS)',
    distance_gps: 'Täglich zurückgelegte Kilometer (GPS)',
    pause: 'Brechen',
    segment: 'Befasst sich mit',
    start_time: 'Startzeit',
    end_time: 'Endzeit',
    service_time: 'Servicezeit',
    waypoint_load: 'Verladung an Bord',
    state: 'Zustand',
    violated_plafond: 'Verletzung der Obergrenze',
    traffic_delay: 'Verkehrsverzögerung',
    company: 'Agentur',
    address: 'Adresse',
    position: 'Position',
    route: 'Weg',
    filter_date: 'Nach Datum filtern',
    warning: 'Aufmerksamkeit',
    no_warning: 'Keine Warnungen',
    alerts: 'Hinweise',
    trucks_with_alerts: 'Fahrzeuge mit Warnungen',
    bluetooth_devices: 'Bluetooth-Geräte',
    no_bluetooth_devices: 'Keine Bluetooth-Geräte gefunden',
    connected: 'In Verbindung gebracht',
    disconnected: 'Getrennt',
    bluetooth_types: {
        temperature_probe: 'Temperaturfühler',
        door_probe: 'Tür und Temperaturfühler'
    },
    back_to_home: 'Zurück nach Hause',
    cerca: 'Nahe',
    search_vehicle: 'Fahrzeug suchen',
    filter: 'Filter',
    filter_label: 'Filter',
    filters_label: 'Filter',
    filter_by: 'Filtern nach',
    operations: 'Operationen',
    filter_vehicles: 'Fahrzeuge filtern',
    filter_applied: 'Filter erfolgreich angewendet',
    filter_canceled: 'Filter erfolgreich gelöscht',
    order_by: 'Sortiere nach',
    order: 'Befehl',
    ascending: 'Wachsend',
    descending: 'Absteigend',
    value: 'Wert',
    select_filter: 'Filter auswählen',
    sort_vehicles: 'Fahrzeuge bestellen',
    sort_for_alphanumeric: 'Alphabetisch',
    sort_for_lastpoint: 'Chronologisch',
    sort_for_company: 'Agentur',
    sort_for_activity: 'Aktivität',
    company_select: 'Unternehmen auswählen',
    no_companies_founded: 'Keine Firmen gefunden',
    multicompany: 'Nach Unternehmen filtern',
    no_vehicles_founded: 'Keine Fahrzeuge gefunden',
    print: 'Drücken Sie',
    export: 'Export',
    print_recap: 'Zusammenfassung drucken',
    select_date: 'Wählen Sie ein Datum aus',
    add: 'hinzufügen',
    add_new: 'Neue hinzufügen',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    options: 'Optionen',
    login: 'Anmeldung',
    logout: 'Du gehst raus',
    logout_confirm: 'Sind Sie sicher, dass Sie aufhören wollen?',
    login_button: 'Anmeldung',
    connect: 'Verbinden',
    logged: 'In Verbindung gebracht',
    manage: 'Verwalten',
    loading: 'Wird geladen',
    loading_timeout:
        'Netzwerkfehler. Bitte laden Sie die Seite neu oder versuchen Sie es später erneut',
    go_back: 'Komm zurück',
    select_coordinates: 'Koordinaten auswählen',
    other: 'Andere',
    options_general: 'Allgemein',
    options_language: 'Zunge',
    change_interface_language: 'Wählen Sie die Sprache der Benutzeroberfläche aus',
    change_infringment_language: 'Wählen Sie das Verstoßland aus',
    change_timezone: 'Wähle Zeitzone aus',
    save_language_settings: 'Spracheinstellungen speichern',
    vehicle_list_options: 'Fahrzeugliste',
    select_view: 'Ansicht auswählen',
    select_fields_placeholder: 'Wählen Sie die anzuzeigenden Felder aus',
    selected_field: 'Ausgewähltes Feld',
    selected_fields: 'Ausgewählte Felder',
    list: 'Aufführen',
    table: 'Tisch',
    view_options: 'Optionen anzeigen',
    fluid_table: 'Dynamische Breite',
    fluid_table_descr: 'Passen Sie die Tabellenbreite basierend auf den ausgewählten Feldern an',
    vehicle_fields_list:
        'Welche Informationen sollen für jedes Fahrzeug angezeigt werden? (Max. 3)',
    vehicle_fields_list_warning:
        'Achtung: Das Feld wird nicht angezeigt, wenn es für das Fahrzeug nicht verfügbar ist',
    vehicle_identification: 'Wie soll das Fahrzeug in der Hauptliste identifiziert werden?',
    apply_to_marker: 'Wenden Sie diese Einstellung auch auf Fahrzeuge auf der Karte an',
    element_icon_section: 'Elementsymbol',
    element_title_section: 'Artikeltitel',
    element_fields_list_section: 'Liste der Elementfelder',
    element_icon_description:
        'Wählen Sie das Symbol aus, das für das Element angezeigt werden soll',
    element_icon: 'Symbol',
    element_icon_color: 'Symbolfarbe',
    options_map: 'Kartenoptionen',
    vehicles_clustering: 'Fahrzeuggruppierung',
    vehicles_clustering_warning:
        'Das Aktualisieren dieser Einstellung erfordert ein Neuladen der Seite',
    options_marker: 'Markierungsoptionen',
    size: 'Abmessungen',
    font_size: 'Textgröße',
    font_color: 'Textfarbe',
    background_color: 'Hintergrundfarbe',
    opacity: 'Transparenz',
    color: 'Farbe',
    small: 'Klein',
    medium: 'Mittel',
    large: 'Großartig',
    additional_info: 'Weitere Informationen',
    analysis_options: 'Routenanalyse',
    options_chart: 'Diagrammoptionen',
    options_timeline: 'Zeitleistenoptionen',
    hide_timeline: 'Zeitleiste ausblenden',
    map: 'Karte',
    silica: 'Silizium',
    google: 'Google',
    google_satellite: 'Google Satellit',
    sandbox: 'Sandkästen',
    silkysand: 'Seidensand',
    gravelpit: 'Kiesgrube',
    open_street_maps: 'Straßenkarten öffnen',
    arcgis_satellite: 'ArcGIS-Satellit',
    traffic: 'Verkehr',
    traffic_anomalies: 'Unfälle und Anomalien',
    heavy_vehicles_ban: 'Verbote für schwere Fahrzeuge',
    driver: 'Treiber',
    no_driver: 'Kein Fahrer',
    drivers: 'Treiber',
    driver_card: 'Fahrerkarte',
    name: 'Vorname',
    surname: 'Nachname',
    driver_activity: 'Fahreraktivität',
    driver_status: 'Fahrerstatus',
    activity_type: 'Art der Aktivität',
    activity: 'Aktivität',
    no_activity: 'Keine Aktivität',
    driver_at_rest: 'Fahrer in Ruhe',
    driver_available: 'Treiber verfügbar',
    driver_at_work: 'Fahrer bei der Arbeit',
    driver_driving: 'Fahrer fährt',
    inactive_vehicle: 'Fahrzeug inaktiv',
    last_driver_detected: 'Letzter Treiber erkannt',
    rest: 'Ausruhen',
    work: 'Arbeiten',
    driving: 'Führung',
    availability: 'Verfügbarkeit',
    codriving: 'Fahren Sie mit einer Crew',
    codriver: 'Besatzung',
    break: 'Sitzung pausieren',
    daily_rest: 'Tägliche Ruhe',
    violation: 'Verstoß',
    to_begin_within: 'Beginnend mit',
    to_end_within: 'Bis zum Ende fertig sein',
    after_total_time_travel: 'Nach einer Reise insgesamt',
    after_total_distance_travel: 'Nach einer zurückgelegten Gesamtstrecke von',
    residual_commitment_type: 'Art der Restverpflichtung',
    residual_commitment: 'Restliches Engagement',
    commitment_hours: 'Stundenlanges Engagement',
    commitment_hours_description:
        'Finden Sie alle Fahrzeuge, deren Fahrer ein größeres Restengagement als angegeben haben',
    driver_plafond: 'Fahrerdecke',
    residual_driving: 'Restliche Anleitung',
    residual_bonuses: 'Restboni',
    consider_bonuses: 'Die Zeit wird unter Berücksichtigung der Restboni berechnet',
    current_activity: 'Momentane Tätigkeit',
    next_activity: 'Nächste Aktivität',
    view_plafond_detail: 'Deckendetail anzeigen',
    card: 'Papier',
    driver_change: 'Fahrerwechsel',
    shared_links: 'Geteilte Links',
    gf: 'DANN erkannt',
    analyze: 'Analysieren',
    plan: 'Planen',
    vehicle: 'Fahrzeug',
    vehicles: 'Fahrzeuge',
    vehicle_info: 'Fahrzeuginformationen',
    vehicle_type: 'Fahrzeugtyp',
    vehicle_body: 'Fahrzeugkarosserie',
    truck_status: 'Fahrzeugstatus',
    brand: 'Marke',
    model: 'Modell',
    no_card: 'Es wurde kein Papier eingelegt',
    no_card_driving: 'Papierloses Fahren',
    speed: 'Geschwindigkeit',
    tacho_speed: 'Geschwindigkeit (Fahrtenschreiber)',
    vehicle_hooked: 'Angekoppeltes Fahrzeug',
    truck_hooked_text: 'Dieses Fahrzeug ist an das folgende Anhänger angekoppelt: ',
    trailer_hooked_text: 'Dieser Anhänger ist an das folgende Fahrzeug angekoppelt: ',
    no_driver_rfid_info: 'Die Karte des Fahrers ist nicht in der Fahrerliste registriert',
    gps_speed: 'Geschwindigkeit (GPS)',
    level: 'Ebene',
    battery: 'Schlagzeug',
    low_battery_level: 'Niedriger Batteriestand',
    fuel: 'Kraftstoff',
    fuel_level: 'Tankfüllstand',
    low_fuel_level: 'Wenig Kraftstoff',
    consumption: 'Verbrauch',
    consumptions: 'Verbrauch',
    fuel_consumption: 'Kraftstoffverbrauch',
    fuel_avg_consumption: 'Durchschnittlicher täglicher Kraftstoffverbrauch',
    altitude: 'Höhe',
    weight: 'Gewicht',
    lastpoint_weight: 'Gewicht (letzter Punkt)',
    vehicle_weight: 'Gewicht (t)',
    vehicle_length: 'Länge (m)',
    vehicle_width: 'Breite (m)',
    vehicle_height: 'Höhe (m)',
    odometer: 'Kilometerzähler',
    odometer_tacho: 'Kilometerzähler (Fahrtenschreiber)',
    odometer_gps: 'Kilometerzähler (GPS)',
    gps_odometer: 'Kilometerzähler (GPS)',
    last_point: 'Letzter Punkt',
    moving: 'In Bewegung',
    stopped: 'Gestoppt',
    engine_on: 'Motor läuft',
    ignition: 'Zündung an',
    engine_off: 'Motor aus',
    engine_on_off: 'Starten des Motors',
    ignition_on: 'Zündung an',
    ignition_off: 'Zündung aus',
    device: 'Gerät',
    plate: 'Platte',
    chassis: 'Chassis',
    status: 'Status',
    custom_id: 'Benutzerdefinierter Name',
    heading: 'Richtung',
    rpm_engine: 'Motordrehzahl',
    pto_active: 'Zapfwelle aktiv',
    pto_state: 'Zapfwellenstatus',
    device_timestamp: 'Zeitstempel',
    info: 'Die Info',
    solar_day_report: 'Kalendertagesbericht',
    telemetry: 'Telemetrie',
    parameters: 'Parameter',
    engine_coolant_temp: 'Motortemperatur',
    engine_rpm: 'Motordrehzahl',
    dallas_temp_1: 'Temperatur 1',
    dallas_temp_2: 'Temperatur 2',
    dallas_temp_id_1: 'Temperatur-ID 1',
    dallas_temp_id_2: 'Temperatur-ID 2',
    digital_input_1: 'Digitaler Eingang 1',
    digital_input_2: 'Digitaler Eingang 2',
    digital_input_3: 'Digitaler Eingang 3',
    digital_input_4: 'Digitaler Eingang 4',
    digital_input_5: 'Digitaler Eingang 5',
    battery_level: 'Batterie Level',
    adblue_liters: 'Liter Adblue',
    adblue_level: 'Adblue-Füllstand',
    gnss_status: 'GNSS-Status',
    gnss_status_values: [
        'Abgenutzt',
        'An, kein GPS',
        'An, keine Satelliten',
        'An, mit Satelliten',
        'An, im Schlaf',
        'Geschützter Status'
    ],
    gnss_off: 'GNSS aus',
    gnss_on_fix: 'GNSS eingeschaltet, mit Satelliten',
    gnss_on_without_fix: 'GNSS an, keine Satelliten',
    gnss_on_sleep: 'GNSS eingeschaltet, im Ruhezustand',
    gnss_on_protection: 'GNSS ein, Schutzstatus',
    gsm_signal: 'GSM-Signal',
    tacho_source: 'Quelle des Fahrtenschreibers',
    external_voltage: 'Diät',
    tacho_overspeed: 'Fahrtenschreiber für Geschwindigkeitsüberschreitungen',
    no_points_title: 'Letzte Aktivität vor mehr als 24 Stunden',
    no_points: 'Dieses Fahrzeug hat seit mehr als 24 Stunden keine Punkte gesendet',
    clutch: 'Kupplung',
    clutch_switch: 'Kupplungsschalter',
    brake_pedal: 'Bremspedal',
    accelerator_pedal: 'Gaspedal',
    pedal_released: 'Pedal losgelassen',
    pedal_pressed: 'Pedal gedrückt',
    data_mode: 'Datenmodus',
    home_stop: 'An Ort und Stelle verriegelt',
    home_moving: 'Unterwegs vor Ort',
    roaming_stop: 'Ich stecke im Roaming fest',
    roaming_moving: 'Unterwegs Roaming',
    unknown_stop: 'Festnahme unbekannt',
    unknown_moving: 'Umzug unbekannt',
    engine_coolant_temperature: 'Kühlmitteltemperatur',
    engine_temperature: 'Motortemperatur',
    cruise_control_active: 'Tempomat aktiv',
    adblue_off: 'Adblue aus',
    adblue_red: 'Adblue rot',
    adblue_yellow: 'Adblue gelb',
    adblue_info: 'Adblue-Info',
    charger_notconnected: 'Ladegerät nicht angeschlossen',
    charger_connected: 'Ladegerät angeschlossen',
    tacho_unknown: 'Unbekannt',
    tacho_kline: 'K-Linie',
    tacho_allcan: 'BUS-CAN',
    tacho_can: 'BUS-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'Das Fahrzeug, auf das Sie zugreifen möchten, ist nicht verfügbar',
    vehicle_with_lock_commands: 'Fahrzeug mit Verriegelungssteuerung',
    lock: 'Block',
    unlock: 'Freischalten',
    send: 'Schicken',
    command_sent: 'Befehl erfolgreich gesendet',
    turn_on: 'Licht an',
    turned_on: 'Eingeschaltet',
    turn_off: 'Ausschalten',
    turned_off: 'Abgenutzt',
    confirm_send_command: 'Sind Sie sicher, dass Sie den Befehl senden möchten?',
    temperature: {
        title: 'Temperaturfühler',
        probe: ['Sonde 1', 'Sonde 2', 'Sonde 3', 'Sonde 4', 'Sonde 5', 'Sonde 6']
    },
    no_power_supply: 'Keine Energie',
    no_gps: 'Kein GPS',
    tacho_clock_from_updated_error: 'Fehler bei der Synchronisierung der Tachographenuhr',
    tacho_clock_from_device_error:
        'Fehler bei der Synchronisierung zwischen Tachograph und Steuereinheit',
    device_timestamp_error: 'ECU-Datumsfehler',
    select_color_mode: 'Wählen Sie den Farbmodus',
    light_mode: 'Lichtmodus',
    dark_mode: 'Dunkler Modus',
    color_blind_mode: 'Farbenblinder Modus',
    color_blind_dark_mode: 'Farbenblinder Dunkelmodus',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Ausgewählte Fahrzeuge',
            search_trucks_title: 'Suche nach Fahrzeugen',
            search_trucks_tags_title: 'Zugehörige Tags',
            search_trucks_status_title: 'Fahrzeugaktivität',
            search_trucks_driver_status_title: 'Fahreraktivität',
            search_near_trucks_title: 'Finden Sie Fahrzeuge in der Nähe'
        },
        detailspanel: {
            bind_vehicle: 'Fahrzeug koppeln',
            add_new_tag: 'Als neues Tag hinzufügen',
            select_new_tag: 'Wählen Sie ein Tag aus oder suchen Sie danach',
            binded_tags: 'Zugehörige Tags',
            binded_tag: 'Zugehöriges Tag'
        },
        analyzer: {
            vehicle_activity_analisys: 'Analyse der Fahrzeugaktivität',
            timeline_separator: 'Gruppiere nach',
            loading_error: 'Beim Laden der Daten ist ein Fehler aufgetreten'
        },
        search_resources: {
            title: 'Suchen Sie nach Ressourcen',
            description:
                'Finden Sie die Tankstellen, Parkplätze und zugänglichen Bereiche in der Nähe Ihres Fahrzeugs.'
        }
    },
    analisys: {
        title: 'Routenanalyse',
        select_telemetries:
            'Wählen Sie die Telemetriedaten aus, die in der Routenanalyse angezeigt werden sollen',
        select_telemetries_description:
            'Es können maximal 4 Telemetriedaten zur Anzeige ausgewählt werden',
        chart_groups: {
            movement_data: 'Bewegungsdaten',
            engine_data: 'Motordaten',
            vehicle_data: 'Fahrzeugdaten',
            sensors_data: 'Sensordaten',
            driver_data: 'Fahrerdaten',
            fuel_data: 'Kraftstoffdaten'
        },
        no_data: 'Keine Daten verfügbar',
        no_data_description:
            'Für dieses Fahrzeug und für den ausgewählten Zeitraum sind keine Routendaten zur Anzeige vorhanden',
        no_data_contact_support: 'Für weitere Informationen wenden Sie sich an den Kundendienst'
    },
    planner: {
        user_config: 'Benutzerkonfigurationen',
        no_user_config: 'Es sind keine Benutzerkonfigurationen zum Anzeigen vorhanden',
        freight_access: 'Zugang zu Frachttaschen',
        vehicle_actual_position: 'Aktuelle Fahrzeugposition',
        plan_route: 'Planen Sie die Route',
        select_on_map: 'Wählen Sie einen Punkt auf der Karte aus',
        select_on_map_option: 'Auf der Karte auswählen',
        fill_route_info: 'Geben Sie die Routeninformationen ein',
        fill_resources: 'Wählen Sie den Punkt aus, den Sie als Wegpunkt hinzufügen möchten',
        fill_waypoints: 'Wählen Sie die Etappen aus',
        load_breaks: 'Ladung ruht',
        load_alternative_routes: 'Alternativrouten laden',
        savedroutes: {
            my_routes: 'Meine Routen',
            saved_routes: 'Gespeicherte Routen',
            no_saved_routes: 'Sie haben noch keine Routen gespeichert',
            success_saved_route: 'Ihre Route wurde erfolgreich gespeichert',
            error_saved_route: 'Beim Speichern der Route ist ein Fehler aufgetreten',
            success_deleted_route: 'Ihre Route wurde erfolgreich gelöscht',
            error_deleted_route: 'Beim Löschen des Pfads ist ein Fehler aufgetreten'
        },
        resources: {
            area_search: 'Suche im Umkreis',
            station_services: 'Inklusive dieser Leistungen',
            parking_services: 'Parkdienstleistungen',
            select_rating: 'Wählen Sie die Partitur aus',
            select_security: 'Wählen Sie Sicherheit aus',
            empty_search: 'Bei der Suche wurden keine anzuzeigenden Elemente gefunden',
            delivery_nation: 'Lieferland',
            fai_featured: 'Lumesia angeschlossen',
            self_service: 'Selbstbedienung',
            served: 'Serviert',
            total_parking_spots: 'Gesamtplätze',
            rating: 'Rückstellung',
            reviews: 'Rezensionen',
            security: 'Sicherheit',
            price_per_night: 'Preis pro Nacht'
        },
        emissions_labels: 'Emissionen',
        emissions: {
            fuelconsumption: 'Kraftstoffverbrauch',
            hydrocarbons: 'Kohlenwasserstoffe',
            methane: 'Methan',
            hydrocarbonsexceptmethane: 'Kohlenwasserstoffe außer Methan',
            carbonmonoxide: 'Kohlenmonoxid',
            carbondioxide: 'Kohlendioxid',
            fossilcarbondioxide: 'Fossiles Kohlendioxid',
            sulphurdioxide: 'Schwefeldioxid',
            nitrogenoxides: 'Stickoxide',
            nitrogendioxide: 'Stickstoffdioxid',
            nitrousoxide: 'Lachgas',
            ammonia: 'Ammoniak',
            benzene: 'Benzol',
            particles: 'Partikel',
            numberofparticles: 'Anzahl der Partikel'
        },
        plafond_configuration: {
            title: 'Deckenkonfiguration',
            driving_time_regulation: 'Fahrzeit',
            last_time_the_driver_worked: 'Letzte Fahreraktivität',
            eu_driving_time_regulation_for_single_day: 'Ein Tag',
            eu_driving_time_regulation_for_single_long_day: 'Einzeltag (mit Bonus)',
            eu_driving_time_regulation_for_multiple_day: 'Mehr Tage',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Mehrere Tage (mit Crew)',
            accumulated_driving_time_since_lastbreak: 'Fahrstunden seit der letzten Pause',
            accumulated_driving_time_since_lastdailyrest:
                'Lenkstunden seit der letzten täglichen Ruhezeit',
            accumulated_travel_time_since_lastdailyrest:
                'Reisestunden seit der letzten täglichen Ruhezeit'
        },
        set_route: 'Route festlegen',
        select_profile: 'Wähle Profil',
        select_co2_class: 'Emissionsklasse auswählen',
        class: 'Klasse',
        billed_roads: 'Gebührenpflichtige Straßen',
        waypointevent: 'Stoppen',
        waypoints_label: 'Stufen',
        add_waypoint: 'Bühne hinzufügen',
        delete_waypoint: 'Stufe löschen',
        select_waypoint: 'Stufe auswählen',
        select_resource: 'Wählen Sie den Punkt aus, den Sie als Wegpunkt hinzufügen möchten',
        last_waypoint: 'Letzter Halt',
        waypoints_check: 'Es sind Etappen zu absolvieren',
        waypoints_required: 'Es sind mindestens 2 Schritte notwendig',
        countryevent: 'Zustand',
        tollevent: 'Mautstelle',
        event: 'Ereignis',
        alpega: 'Alpega',
        alpega_login: 'Anmelden Alpega',
        alpega_searches: 'Ihre Forschung',
        timocom: 'Timocom',
        pickup: 'Zurückziehen',
        delivery: 'Lieferung',
        extimated_cost: 'Geschätzte Kosten',
        calculate_route: 'Route berechnen',
        calculate_isocrona: 'Isochrone berechnen',
        complete_step_2: 'Schließen Sie Schritt 2 ab',
        reset_route: 'Neue Route',
        route_error: 'Beim Berechnen der Route ist ein Fehler aufgetreten',
        edit_route: 'Route bearbeiten',
        save_route: 'Route speichern',
        delete_route: 'Route löschen',
        route_name: 'Pfadname',
        override_route: 'Pfad überschreiben',
        save_as_new_route: 'Neue Route speichern',
        calculate_route_for: 'Route berechnen für',
        ptvexport: 'Export in den PTV Navigator',
        isocrona: 'Erreichbare Bereiche',
        reset_isocrona: 'Erreichbare Bereiche zurücksetzen',
        search_location: 'Standorte suchen',
        search_station: 'Händler suchen',
        search_parking: 'Parkplatz suchen',
        search_poi: 'POI suchen',
        include_plafond: 'Berücksichtigen Sie die Fahrerbeschränkung bei der Routenberechnung',
        include_plafond_near_vehicles:
            'Finden Sie Fahrzeuge mit Fahrern, die noch ausreichend Engagement haben, um am gesuchten Ort anzukommen',
        violated_plafond: 'Die ausgewählte Route verstößt gegen das Fahrerlimit'
    },
    sharedlinks: {
        back_to_links: 'Gehen Sie zurück zu den Links',
        create_link: 'Erstellen Sie Links',
        update_link: 'Link aktualisieren',
        edit_link: 'Link bearbeiten',
        update_link_expire_date: 'Ablaufdatum aktualisieren',
        delete_link: 'Link löschen',
        delete_link_confirm: 'Den ausgewählten Link löschen?',
        all_your_shared_links: 'Alle Ihre geteilten Links',
        no_shared_links: 'Sie haben noch keine Fahrzeuge geteilt',
        vehicles_link: 'Links für Fahrzeuge',
        select_share_vehicles: 'Wählen Sie Fahrzeuge zum Teilen aus',
        select_start_expire_date: 'Wählen Sie Start- und Ablaufdatum',
        created_link_error: 'Fehler beim Erstellen des Links',
        created_link_success: 'Link erfolgreich erstellt',
        updated_link_error: 'Fehler beim Aktualisieren des Links',
        updated_link_success: 'Link erfolgreich aktualisiert',
        service_description:
            'Teilen Sie diesen Link, um die Verfolgung ausgewählter Fahrzeuge anzuzeigen',
        link_preview: 'Linkvorschau',
        share_single_vehicle: 'Teilen Sie den Standort dieses Fahrzeugs',
        track_vehicle_position: 'Verfolgen Sie den Standort ausgewählter Fahrzeuge',
        start_date_warning: 'Es ist nicht möglich, das Startdatum zu ändern',
        start_date_condition:
            'Wenn Sie das Startdatum ändern möchten, müssen Sie einen neuen Link erstellen',
        expire_date_description:
            'Gibt ein Ablaufdatum an, nach dem dieser Link nicht mehr verfügbar ist',
        start_expire_date_description:
            'Geben Sie ein Anfangsdatum an, ab dem dieser Link verfügbar sein wird, und ein Ablaufdatum, nach dem dieser Link nicht mehr verfügbar sein wird:'
    },
    viewsharedlink: {
        invalid_date_range:
            'Der von Ihnen ausgewählte Datumsbereich ist für diesen Link nicht gültig',
        too_early_link: 'Der Link, auf den Sie zugreifen möchten, ist noch nicht verfügbar',
        too_late_link: 'Der Link, auf den Sie zugreifen möchten, existiert nicht mehr',
        show_route: 'Zeige Weg'
    },
    tags: {
        manage_tags: 'Tags verwalten',
        add_tag: 'Tags hinzufügen',
        add_tag_placeholder: 'Geben Sie den Tag-Namen ein',
        add_tag_error: 'Fehler beim Hinzufügen des Tags',
        add_tag_success: 'Tag erfolgreich hinzugefügt',
        delete_tag: 'Tags löschen',
        delete_tag_confirm: 'Ausgewähltes Tag löschen?',
        delete_tag_error: 'Fehler beim Löschen des Tags',
        delete_tag_success: 'Tag erfolgreich gelöscht',
        update_tag: 'Tags aktualisieren',
        update_tag_error: 'Fehler beim Aktualisieren des Tags',
        update_tag_success: 'Tag erfolgreich aktualisiert',
        all_your_tags: 'Alle deine Tags',
        no_tags: 'Sie haben noch keine Tags erstellt',
        tag_name: 'Verlinke den Namen'
    },
    manage_poi: {
        title: 'POIs verwalten',
        your_pois: 'Ihre POIs',
        add_poi: 'POI hinzufügen',
        no_pois: 'Sie haben noch keine POIs erstellt',
        fields: {
            name: 'Vorname',
            type: 'Kerl',
            color: 'Farbe',
            note: 'Notiz',
            address: 'Adresse suchen',
            latitude: 'Breite',
            longitude: 'Längengrad',
            radius: 'Suchradius',
            radius_description:
                'Um Geofencing zu aktivieren, legen Sie einen Wert größer als Null fest'
        },
        types_poi: {
            all: 'Alle',
            headquarter: 'Website',
            truck_washes: 'LKW-Autowaschanlagen',
            blacklist: 'Schwarze Liste',
            end_of_the_line: 'Endstation',
            customers: 'Kunden',
            stops: 'Stoppen',
            suppliers: 'Anbieter',
            interports: 'Interportiert',
            markets: 'Märkte',
            large_square: 'Quadrate',
            poi_distributors: 'DANN Distributoren',
            poi_workshops: 'DANN Workshops',
            harbors: 'Häfen',
            restaurants: 'Restaurants',
            stations: 'Stationen'
        },
        messages: {
            add_poi_error: 'Fehler beim Hinzufügen des POI',
            add_poi_success: 'DANN erfolgreich hinzugefügt',
            update_poi_error: 'Fehler beim Aktualisieren des POI',
            update_poi_success: 'DANN erfolgreich aktualisiert',
            delete_poi_error: 'Fehler beim Löschen des POI',
            delete_poi_success: 'DANN erfolgreich gelöscht'
        }
    },
    vehicles_cross: {
        title: 'Fahrzeugdurchfahrt',
        fields: {
            address: 'Adresse',
            radius: 'Radius',
            time_interval: 'Zeitraffer',
            time_interval_description: 'Wählen Sie einen maximalen Zeitbereich von 24 Stunden'
        },
        messages: {
            max_time_interval: 'Der maximale Zeitbereich beträgt 24 Stunden',
            error_searching: 'Bei der Suche nach Fahrzeugen ist ein Fehler aufgetreten',
            searching: 'Forschung läuft...',
            long_loading: 'Die Suche kann einige Minuten dauern, bitte warten...',
            no_vehicles_cross: 'Keine Fahrzeuge gefunden'
        }
    },
    plafonds: {
        session: 'Sitzungspause',
        break: 'Pause',
        drive_occurring: 'Es findet ein Fahrzeitraum statt:',
        drive_max_label: 'Maximale Fahrzeit',
        drive_max_value: '4h 30m',
        drive_done: 'Gefahrene Strecke',
        session_title: 'Information zur Unterbrechungspause',
        session_tooltip: 'Es ist möglich, eine unterbrochene Pause zu machen',
        session_done: 'Eine Pause von mindestens 15 Minuten wurde festgestellt.',
        session_info: 'Erster Teil einer unterbrochenen Pause bereits gemacht',
        session_descr:
            "Wir erinnern daran, dass gemäß den geltenden Vorschriften eine Pause von 15'+30' oder 15'+15'+15' möglich ist (abhängig von der Art des Transports).",
        session_choice:
            'Es ist möglich, innerhalb der nächsten <b>30m</b> eine Pause von mindestens',
        brake_start: 'Pausenbeginn',
        brake_done: 'Pause gemacht',
        daily: 'Täglich',
        weekly: 'Wöchentlich',
        driver_in_drive: 'Du fährst vorbei',
        driver_in_rest: 'Restruhe',
        driver_in_work: 'Arbeiten',
        driver_in_available: 'Verfügbarkeit',
        driver_in_availability: 'Verfügbarkeit',
        current_activity: 'Momentane Tätigkeit',
        mini_plafond: 'Mini-Decke',
        last_update: 'Letztes Update',
        panel_title: 'Deckenpaneel',
        residual_commitment: 'Restliches Engagement',
        daily_driving: 'Tagesführer',
        weekly_guide: 'Wöchentlicher Führer',
        biweekly_guide: 'Zweiwöchentlicher Führer',
        last_rest: 'Letzte wöchentliche Ruhezeit',
        minimal_duration_next: 'Mindestdauer als nächstes',
        top_start_until: 'Beginnend mit',
        reduced_weekly_rest: 'Reduzierte wöchentliche Ruhezeiten zum Ausgleich',
        time_to_compensate: 'Zeit zum Ausgleich',
        time_to_compensate_until: 'Zu entschädigen durch',
        footer_message: '* Die Zeit wird unter Berücksichtigung der Restboni berechnet',
        driver_in_block: 'Fahrerlimit ausgeschöpft',
        résidu_commitment: 'Restliches Engagement',
        week_guide: 'Hebdomadaire-Führer',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: 'Fehler 403',
            description:
                'Sehr geehrter Kunde, wir informieren Sie, dass Ihr Konto derzeit nicht für den Zugriff auf diesen Dienst konfiguriert ist. Um Informationen über den Dienst selbst zu erhalten',
            retry_login: 'Bitte versuchen Sie erneut, sich anzumelden',
            contact_us: 'Kontaktiere uns'
        }
    },
    ptv: {
        maxpermittedweight: 'Schwergewicht',
        minpermittedweight: 'Mindestgewicht',
        zonename: 'Bereich',
        unauthorizedtype: 'Nicht autorisierter Code',
        maxemissionstandardeu: 'Emissionsklasse',
        fueltype: 'Treibstoffart',
        loadtype: 'Art des Transports',
        opening: 'Fahrt',
        maxheight: 'maximale Höhe',
        maxweight: 'Schwergewicht',
        maxwidth: 'Maximale Breite',
        maxlength: 'Maximale Länge',
        absolutespeed: 'Geschwindigkeit',
        hazardoustowaters: 'Fahrverbot für Fahrzeuge, die wassergefährdende Güter befördern',
        hazardousgoods: 'Verbot für Fahrzeuge mit gefährlichen Gütern',
        combustibles: 'Verbot der Mitnahme von Treibstoff durch Fahrzeuge',
        maxaxleload: 'Maximale Belastung der Achsen',
        totalpermittedweight: 'Schwergewicht',
        tunnelrestriction: 'Tunnelbeschränkungscode',
        kingpintorearaxle: 'Abstand vom Achsschenkelbolzen zur Achse',
        numberofaxles: 'Anzahl der Achsen',
        length: 'Strecke',
        delay: 'Verzögerung',
        message: {
            text: 'Nachricht'
        }
    },
    options_interface: 'Optionsschnittstelle'
}
