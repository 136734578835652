export default {
    open: 'Você abre',
    close: 'Fechar',
    apply: 'Aplicar',
    cancel: 'Cancelar',
    search: 'Aproximar',
    cancel_search: 'Cancelar pesquisa',
    edit_search: 'Editar pesquisa',
    erase: 'Cancelar',
    back_to_results: 'Volte para os resultados',
    save: 'Salvar',
    retry: 'Tente novamente',
    click_here: 'Clique aqui',
    copy: 'cópia de',
    copied: 'Copiado',
    copied_coordinates: 'Coordenadas copiadas',
    select: 'Selecione',
    selected: 'Selecionado',
    selected_plural: 'Selecionado',
    deselect: 'Desmarcar',
    select_multi_vehicles: 'Selecione um ou mais veículos',
    include: 'Incluir',
    exclude: 'Excluir',
    balanced: 'Equilibrado',
    input: 'Entrada',
    output: 'Saída',
    verified: 'Verificado',
    empty_search: 'Nenhum resultado encontrado',
    read_more: 'Consulte Mais informação',
    details: 'Detalhes',
    actual: 'Atual',
    residual_time: 'Tempo restante',
    next: 'Próximo',
    previous: 'Anterior',
    menu_open: 'Abrir menu',
    hide: 'Esconder',
    show: 'Mostrar',
    none: 'Ninguém',
    none_alt: 'Nenhum',
    all: 'Todos',
    all_alt: 'Todos',
    default: 'Padrão',
    settings: 'Configurações',
    reset_settings: 'Redefinir as configurações',
    reset_settings_success: 'Configurações redefinidas com sucesso',
    multiselect_default_value: {
        singular: 'Valor selecionado:',
        plural: 'Valores selecionados:'
    },
    available: 'Disponível',
    unavailable: 'Não disponível',
    not_available: 'Não disponível',
    not_available_plural: 'Não disponível',
    from: 'de',
    to: 'para o',
    of: 'De',
    yes: 'Sim',
    no: 'Não',
    minutes: 'Minutos',
    hours: 'Horas',
    hour: 'Agora',
    days: 'Dias',
    day: 'Dia',
    months: 'Meses',
    month: 'Mês',
    years: 'Anos',
    year: 'Ano',
    days_names: [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado'
    ],
    days_names_short: ['Sol', 'seg', 'Março', 'qua', 'Quinta', 'sex', 'Sentado'],
    months_names: [
        'Janeiro',
        'Fevereiro',
        'Marchar',
        'abril',
        'Poderia',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'novembro',
        'dezembro'
    ],
    months_names_short: [
        'Geral',
        'fevereiro',
        'Março',
        'abril',
        'Mag',
        'Abaixo',
        'julho',
        'Agosto',
        'Definir',
        'Outubro',
        'Novembro',
        'dezembro'
    ],
    areas: 'Áreas',
    area: 'Área',
    time: 'Tempo (min)',
    time_label: 'Tempo',
    cost: 'Custo',
    toll_cost: 'Custo de pedágio',
    km_cost: 'Custo por km',
    hourly_cost: 'Custo por hora',
    working_cost: 'Custo total do homem',
    total_cost: 'Custo total',
    expire_date: 'Data de validade',
    start_date: 'Data de início',
    recap: 'Resumo',
    start: 'Começar',
    starting_point: 'Partida',
    end: 'fim',
    finish_point: 'eu chego',
    duration: 'Duração',
    distance: 'Distância',
    daily_distance: 'Km percorridos diariamente',
    distance_total: 'Distância total',
    gps_distance: 'Distância (GPS)',
    distance_gps: 'Km percorridos diariamente (GPS)',
    pause: 'Quebrar',
    segment: 'Lida com',
    start_time: 'Hora de início',
    end_time: 'Fim do tempo',
    service_time: 'Tempo de serviço',
    waypoint_load: 'Carregando a bordo',
    state: 'Estado',
    violated_plafond: 'Violação do teto',
    traffic_delay: 'Atraso no trânsito',
    company: 'Agência',
    address: 'Endereço',
    position: 'Posição',
    route: 'Caminho',
    filter_date: 'Filtrar por data',
    warning: 'Atenção',
    no_warning: 'Sem avisos',
    alerts: 'Avisos',
    trucks_with_alerts: 'Veículos com avisos',
    bluetooth_devices: 'Dispositivos Bluetooth',
    no_bluetooth_devices: 'Nenhum dispositivo Bluetooth encontrado',
    connected: 'Conectado',
    disconnected: 'Desconectado',
    bluetooth_types: {
        temperature_probe: 'Sonda de temperatura',
        door_probe: 'Porta e sonda de temperatura'
    },
    back_to_home: 'De volta para casa',
    cerca: 'Aproximar',
    search_vehicle: 'Pesquisar veículo',
    filter: 'Filtro',
    filter_label: 'Filtro',
    filters_label: 'Filtros',
    filter_by: 'Filtrar por',
    operations: 'Operações',
    filter_vehicles: 'Filtrar veículos',
    filter_applied: 'Filtro aplicado com sucesso',
    filter_canceled: 'Filtro excluído com sucesso',
    order_by: 'Ordenar por',
    order: 'Ordem',
    ascending: 'Crescente',
    descending: 'descendente',
    value: 'Valor',
    select_filter: 'Selecione o filtro',
    sort_vehicles: 'Encomendar veículos',
    sort_for_alphanumeric: 'Alfabético',
    sort_for_lastpoint: 'Cronológico',
    sort_for_company: 'Agência',
    sort_for_activity: 'Atividade',
    company_select: 'Selecione a empresa',
    no_companies_founded: 'Nenhuma empresa encontrada',
    multicompany: 'Filtrar por empresa',
    no_vehicles_founded: 'Nenhum veículo encontrado',
    print: 'Imprensa',
    export: 'Exportar',
    print_recap: 'Imprimir resumo',
    select_date: 'Selecione uma data',
    add: 'adicionar',
    add_new: 'Adicionar novo',
    delete: 'Excluir',
    edit: 'Editar',
    options: 'Opções',
    login: 'Conecte-se',
    logout: 'Você sai',
    logout_confirm: 'Você tem certeza que quer sair?',
    login_button: 'Conecte-se',
    connect: 'Conectar',
    logged: 'Conectado',
    manage: 'Gerenciar',
    loading: 'Carregando',
    loading_timeout: 'Erro de rede. Atualize a página ou tente novamente mais tarde',
    go_back: 'Voltar',
    select_coordinates: 'Selecione as coordenadas',
    other: 'Outro',
    options_general: 'Em geral',
    options_language: 'Língua',
    change_interface_language: 'Selecione o idioma da interface',
    change_infringment_language: 'Selecione o país da infração',
    change_timezone: 'Selecione o fuso horário',
    save_language_settings: 'Salvar configurações de idioma',
    vehicle_list_options: 'Lista de veículos',
    select_view: 'Selecione a visualização',
    select_fields_placeholder: 'Selecione os campos para visualizar',
    selected_field: 'Campo selecionado',
    selected_fields: 'Campos selecionados',
    list: 'Lista',
    table: 'Mesa',
    view_options: 'Ver opções',
    fluid_table: 'Largura dinâmica',
    fluid_table_descr: 'Torne a largura da tabela fluida com base nos campos selecionados',
    vehicle_fields_list: 'Que informações você deseja que apareça para cada veículo? (Máx. 3)',
    vehicle_fields_list_warning:
        'Atenção: o campo não será exibido se não estiver disponível para o veículo',
    vehicle_identification: 'Como deseja que o veículo seja identificado na lista principal?',
    apply_to_marker: 'Aplique também esta configuração aos veículos no mapa',
    element_icon_section: 'Ícone do elemento',
    element_title_section: 'Título do item',
    element_fields_list_section: 'Lista de campos de elementos',
    element_icon_description: 'Selecione o ícone a ser exibido para o item',
    element_icon: 'Ícone',
    element_icon_color: 'Cor do ícone',
    options_map: 'Opções de mapa',
    vehicles_clustering: 'Agrupamento de veículos',
    vehicles_clustering_warning:
        'A atualização desta configuração requer o recarregamento da página',
    options_marker: 'Opções de marcador',
    size: 'Dimensão',
    font_size: 'Tamanho do texto',
    font_color: 'Cor do texto',
    background_color: 'Cor de fundo',
    opacity: 'Transparência',
    color: 'Cor',
    small: 'Pequeno',
    medium: 'Médio',
    large: 'Ótimo',
    additional_info: 'Informações adicionais',
    analysis_options: 'Análise de rota',
    options_chart: 'Opções de gráfico',
    options_timeline: 'Opções de linha do tempo',
    hide_timeline: 'Ocultar linha do tempo',
    map: 'Mapa',
    silica: 'Sílica',
    google: 'Google',
    google_satellite: 'Google Satélite',
    sandbox: 'Caixas de areia',
    silkysand: 'Areia sedosa',
    gravelpit: 'Pedreira',
    open_street_maps: 'Abrir mapas de ruas',
    arcgis_satellite: 'Satélite ArcGIS',
    traffic: 'Tráfego',
    traffic_anomalies: 'Acidentes e anomalias',
    heavy_vehicles_ban: 'Proibições de veículos pesados',
    driver: 'Motorista',
    no_driver: 'Sem motorista',
    drivers: 'Motoristas',
    driver_card: 'Cartão de motorista',
    name: 'Primeiro nome',
    surname: 'Sobrenome',
    driver_activity: 'Atividade do motorista',
    driver_status: 'Status do motorista',
    activity_type: 'Tipo de atividade',
    activity: 'Atividade',
    no_activity: 'Nenhuma atividade',
    driver_at_rest: 'Motorista em repouso',
    driver_available: 'Motorista disponível',
    driver_at_work: 'Motorista no trabalho',
    driver_driving: 'Condução de motorista',
    inactive_vehicle: 'Veículo inativo',
    last_driver_detected: 'Último driver detectado',
    rest: 'Descansar',
    work: 'Trabalhar',
    driving: 'Guia',
    availability: 'Disponibilidade',
    codriving: 'Dirija com uma tripulação',
    codriver: 'Equipe',
    break: 'Pausar sessão',
    daily_rest: 'Descanso diário',
    violation: 'Violação',
    to_begin_within: 'Para ser iniciado por',
    to_end_within: 'Para ser finalizado por',
    after_total_time_travel: 'Depois de uma viagem total de',
    after_total_distance_travel: 'Após uma distância total percorrida de',
    residual_commitment_type: 'Tipo de compromisso residual',
    residual_commitment: 'Compromisso residual',
    commitment_hours: 'Horas de compromisso',
    commitment_hours_description:
        'Encontre todos os veículos cujos condutores tenham um compromisso residual superior ao indicado',
    driver_plafond: 'Teto do driver',
    residual_driving: 'Orientação residual',
    residual_bonuses: 'Bônus residuais',
    consider_bonuses: 'O tempo é calculado considerando os bônus residuais',
    current_activity: 'Atividade atual',
    next_activity: 'Próxima atividade',
    view_plafond_detail: 'Ver detalhe do teto',
    card: 'Papel',
    driver_change: 'Mudança de motorista',
    shared_links: 'Links compartilhados',
    gf: 'ENTÃO Detectado',
    analyze: 'Analisar',
    plan: 'Plano',
    vehicle: 'Veículo',
    vehicles: 'Veículos',
    vehicle_info: 'Informações do veículo',
    vehicle_type: 'Tipo de Veículo',
    vehicle_body: 'Corpo do veículo',
    truck_status: 'Estado do veículo',
    brand: 'Marca',
    model: 'Modelo',
    no_card: 'Papel não inserido',
    no_card_driving: 'Condução sem papel',
    speed: 'Velocidade',
    tacho_speed: 'Velocidade (tacógrafo)',
    vehicle_hooked: 'Veículo atado',
    truck_hooked_text: 'Este veículo está atado ao seguinte reboque: ',
    trailer_hooked_text: 'Este reboque está atado ao seguinte veículo: ',
    no_driver_rfid_info: 'A carteira do motorista não está registrada na lista de motoristas',
    gps_speed: 'Velocidade (GPS)',
    level: 'Nível',
    battery: 'Bateria',
    low_battery_level: 'Nível de bateria baixo',
    fuel: 'Combustível',
    fuel_level: 'Nível de combustível',
    low_fuel_level: 'Baixo combustível',
    consumption: 'Consumo',
    consumptions: 'Consumo',
    fuel_consumption: 'Consumo de combustível',
    fuel_avg_consumption: 'Consumo médio diário de combustível',
    altitude: 'Altitude',
    weight: 'Peso',
    lastpoint_weight: 'Peso (último ponto)',
    vehicle_weight: 'Peso (t)',
    vehicle_length: 'Comprimento (m)',
    vehicle_width: 'Largura (m)',
    vehicle_height: 'Altura (m)',
    odometer: 'Odômetro',
    odometer_tacho: 'Odômetro (tacógrafo)',
    odometer_gps: 'Odômetro (GPS)',
    gps_odometer: 'Odômetro (GPS)',
    last_point: 'Último ponto',
    moving: 'Em movimento',
    stopped: 'Parou',
    engine_on: 'Motor funcionando',
    ignition: 'Ignição ligada',
    engine_off: 'Motor desligado',
    engine_on_off: 'Ligando o motor',
    ignition_on: 'Ignição ligada',
    ignition_off: 'Ignição desligada',
    device: 'Dispositivo',
    plate: 'Placa',
    chassis: 'Chassis',
    status: 'Status',
    custom_id: 'Nome personalizado',
    heading: 'Direção',
    rpm_engine: 'Rotação do motor',
    pto_active: 'Tomada de força ativa',
    pto_state: 'Estado da tomada de força',
    device_timestamp: 'Carimbo de data e hora',
    info: 'Informações',
    solar_day_report: 'Relatório do dia do calendário',
    telemetry: 'Telemetria',
    parameters: 'Parâmetros',
    engine_coolant_temp: 'Temperatura do motor',
    engine_rpm: 'Rotação do motor',
    dallas_temp_1: 'Temperatura 1',
    dallas_temp_2: 'Temperatura 2',
    dallas_temp_id_1: 'ID de temperatura 1',
    dallas_temp_id_2: 'ID de temperatura 2',
    digital_input_1: 'Entrada digital 1',
    digital_input_2: 'Entrada digital 2',
    digital_input_3: 'Entrada digital 3',
    digital_input_4: 'Entrada digital 4',
    digital_input_5: 'Entrada digital 5',
    battery_level: 'Nível de bateria',
    adblue_liters: 'Litros Adblue',
    adblue_level: 'Nível Adblue',
    gnss_status: 'Estado do GNSS',
    gnss_status_values: [
        'Esgotado',
        'Ligado, sem GPS',
        'Ligado, sem satélites',
        'Ligado, com satélites',
        'Ligado, dormindo',
        'Status protegido'
    ],
    gnss_off: 'GNSS desligado',
    gnss_on_fix: 'GNSS ligado, com satélites',
    gnss_on_without_fix: 'GNSS ativado, sem satélites',
    gnss_on_sleep: 'GNSS ligado, durante o sono',
    gnss_on_protection: 'GNSS ativado, status de proteção',
    gsm_signal: 'Sinal GSM',
    tacho_source: 'Fonte do tacógrafo',
    external_voltage: 'Dieta',
    tacho_overspeed: 'Tacógrafo de excesso de velocidade',
    no_points_title: 'Última atividade há mais de 24 horas',
    no_points: 'Este veículo não envia nenhum ponto há mais de 24 horas',
    clutch: 'Embreagem',
    clutch_switch: 'Interruptor de embreagem',
    brake_pedal: 'Pedal de freio',
    accelerator_pedal: 'Pedal acelerador',
    pedal_released: 'Pedal liberado',
    pedal_pressed: 'Pedal pressionado',
    data_mode: 'Modo de dados',
    home_stop: 'Trancado no lugar',
    home_moving: 'Em movimento no local',
    roaming_stop: 'Preso em roaming',
    roaming_moving: 'Em movimento em roaming',
    unknown_stop: 'Prisão desconhecida',
    unknown_moving: 'Movendo desconhecido',
    engine_coolant_temperature: 'Temperatura do líquido refrigerante',
    engine_temperature: 'Temperatura do motor',
    cruise_control_active: 'Controle de cruzeiro ativo',
    adblue_off: 'Adblue desligado',
    adblue_red: 'Adblue vermelho',
    adblue_yellow: 'Adblue amarelo',
    adblue_info: 'Informações do Adblue',
    charger_notconnected: 'Carregador não conectado',
    charger_connected: 'Carregador conectado',
    tacho_unknown: 'Desconhecido',
    tacho_kline: 'Linha K',
    tacho_allcan: 'ÔNIBUS-CAN',
    tacho_can: 'ÔNIBUS-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'O veículo que você está tentando acessar não está disponível',
    vehicle_with_lock_commands: 'Veículo com controles de travamento',
    lock: 'Bloquear',
    unlock: 'Desbloquear',
    send: 'Enviar',
    command_sent: 'Comando enviado com sucesso',
    turn_on: 'luzes ligadas',
    turned_on: 'Ligado',
    turn_off: 'Desligar',
    turned_off: 'Esgotado',
    confirm_send_command: 'Tem certeza de que deseja enviar o comando?',
    temperature: {
        title: 'Sondas de temperatura',
        probe: ['Sonda 1', 'Sonda 2', 'Sonda 3', 'Sonda 4', 'Sonda 5', 'Sonda 6']
    },
    no_power_supply: 'Nenhum poder',
    no_gps: 'Sem GPS',
    tacho_clock_from_updated_error: 'Erro de sincronização do relógio do tacógrafo',
    tacho_clock_from_device_error:
        'Erro de sincronização entre o tacógrafo e a unidade de controle',
    device_timestamp_error: 'Erro de data da ECU',
    select_color_mode: 'Selecione o modo de cor',
    light_mode: 'Modo claro',
    dark_mode: 'Modo escuro',
    color_blind_mode: 'Modo daltônico',
    color_blind_dark_mode: 'Modo escuro daltônico',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Veículos em destaque',
            search_trucks_title: 'Pesquisar veículos',
            search_trucks_tags_title: 'Tags associadas',
            search_trucks_status_title: 'Atividade do veículo',
            search_trucks_driver_status_title: 'Atividade do motorista',
            search_near_trucks_title: 'Encontre veículos próximos'
        },
        detailspanel: {
            bind_vehicle: 'Emparelhar veículo',
            add_new_tag: 'Adicionar como nova tag',
            select_new_tag: 'Selecione ou pesquise uma tag',
            binded_tags: 'Tags associadas',
            binded_tag: 'Etiqueta associada'
        },
        analyzer: {
            vehicle_activity_analisys: 'Análise da atividade do veículo',
            timeline_separator: 'Agrupar por',
            loading_error: 'Ocorreu um erro ao carregar dados'
        },
        search_resources: {
            title: 'Procure recursos',
            description:
                'Encontre os postos de gasolina, estacionamentos e áreas acessíveis mais próximas do seu veículo.'
        }
    },
    analisys: {
        title: 'Análise de rota',
        select_telemetries: 'Selecione as telemetrias a serem exibidas na análise de rota',
        select_telemetries_description: 'É possível selecionar no máximo 4 telemetrias para exibir',
        chart_groups: {
            movement_data: 'Dados de movimento',
            engine_data: 'Dados do motor',
            vehicle_data: 'Dados do veículo',
            sensors_data: 'Dados do sensor',
            driver_data: 'Dados do motorista',
            fuel_data: 'Dados de combustível'
        },
        no_data: 'Não há dados disponíveis',
        no_data_description:
            'Não há dados de rota para exibir para este veículo ou para o período selecionado',
        no_data_contact_support:
            'Entre em contato com o suporte ao cliente para obter mais informações'
    },
    planner: {
        user_config: 'Configurações do usuário',
        no_user_config: 'Não há configurações de usuário para visualizar',
        freight_access: 'Acesso a malas de carga',
        vehicle_actual_position: 'Posição atual do veículo',
        plan_route: 'Planejar rota',
        select_on_map: 'Selecione um ponto no mapa',
        select_on_map_option: 'Selecione no mapa',
        fill_route_info: 'Preencha as informações da rota',
        fill_resources: 'Selecione o ponto a ser adicionado como waypoint',
        fill_waypoints: 'Selecione as etapas',
        load_breaks: 'Descansos de carga',
        load_alternative_routes: 'Carregar rotas alternativas',
        savedroutes: {
            my_routes: 'Minhas rotas',
            saved_routes: 'Rotas salvas',
            no_saved_routes: 'Você ainda não salvou nenhuma rota',
            success_saved_route: 'Sua rota foi salva com sucesso',
            error_saved_route: 'Ocorreu um erro ao salvar a rota',
            success_deleted_route: 'Sua rota foi excluída com sucesso',
            error_deleted_route: 'Ocorreu um erro ao excluir o caminho'
        },
        resources: {
            area_search: 'Pesquisar dentro do raio',
            station_services: 'Incluindo esses serviços',
            parking_services: 'Serviços de estacionamento',
            select_rating: 'Selecione a pontuação',
            select_security: 'Selecione segurança',
            empty_search: 'A pesquisa não encontrou nenhum item para exibir',
            delivery_nation: 'País de entrega',
            fai_featured: 'Afiliada à Lumesia',
            self_service: 'Self-service',
            served: 'Servido',
            total_parking_spots: 'Total de lugares',
            rating: 'Acumulação',
            reviews: 'Avaliações',
            security: 'Segurança',
            price_per_night: 'Preço por noite'
        },
        emissions_labels: 'Emissões',
        emissions: {
            fuelconsumption: 'Consumo de combustível',
            hydrocarbons: 'Hidrocarbonetos',
            methane: 'Metano',
            hydrocarbonsexceptmethane: 'Hidrocarbonetos exceto metano',
            carbonmonoxide: 'Monóxido de carbono',
            carbondioxide: 'Dióxido de carbono',
            fossilcarbondioxide: 'Dióxido de carbono fóssil',
            sulphurdioxide: 'Dióxido de enxofre',
            nitrogenoxides: 'Óxidos de nitrogênio',
            nitrogendioxide: 'Dióxido de nitrogênio',
            nitrousoxide: 'Óxido nitroso',
            ammonia: 'Amônia',
            benzene: 'Benzeno',
            particles: 'Partículas',
            numberofparticles: 'Número de partículas'
        },
        plafond_configuration: {
            title: 'Configuração de teto',
            driving_time_regulation: 'Tempo de condução',
            last_time_the_driver_worked: 'Última atividade do motorista',
            eu_driving_time_regulation_for_single_day: 'Único dia',
            eu_driving_time_regulation_for_single_long_day: 'Único dia (com bônus)',
            eu_driving_time_regulation_for_multiple_day: 'Mais dias',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Vários dias (com tripulação)',
            accumulated_driving_time_since_lastbreak: 'Horas de condução desde a última pausa',
            accumulated_driving_time_since_lastdailyrest:
                'Horas de condução desde o último descanso diário',
            accumulated_travel_time_since_lastdailyrest:
                'Horas de viagem desde o último descanso diário'
        },
        set_route: 'Definir rota',
        select_profile: 'Selecione o perfil',
        select_co2_class: 'Selecione a classe de emissão',
        class: 'Aula',
        billed_roads: 'Rodovias com pedágio',
        waypointevent: 'Parar',
        waypoints_label: 'Estágios',
        add_waypoint: 'Adicionar estágio',
        delete_waypoint: 'Excluir estágio',
        select_waypoint: 'Selecione o estágio',
        select_resource: 'Selecione o ponto a ser adicionado como waypoint',
        last_waypoint: 'Última parada',
        waypoints_check: 'Existem etapas para completar',
        waypoints_required: 'São necessárias pelo menos 2 etapas',
        countryevent: 'Estado',
        tollevent: 'Cabine de Pedágio',
        event: 'Evento',
        alpega: 'Alpega',
        alpega_login: 'Login Alpega',
        alpega_searches: 'Sua pesquisa',
        timocom: 'Timocom',
        pickup: 'Retirar',
        delivery: 'Entrega',
        extimated_cost: 'Custo estimado',
        calculate_route: 'Calcular rota',
        calculate_isocrona: 'Calcular isócrona',
        complete_step_2: 'Conclua a etapa 2',
        reset_route: 'Nova rota',
        route_error: 'Ocorreu um erro ao calcular a rota',
        edit_route: 'Editar rota',
        save_route: 'Salvar rota',
        delete_route: 'Excluir rota',
        route_name: 'Nome do caminho',
        override_route: 'Substituir caminho',
        save_as_new_route: 'Salvar nova rota',
        calculate_route_for: 'Calcular rota para',
        ptvexport: 'Exportar para PTV Navigator',
        isocrona: 'Áreas acessíveis',
        reset_isocrona: 'Redefinir áreas acessíveis',
        search_location: 'Pesquisar locais',
        search_station: 'Pesquisar distribuidor',
        search_parking: 'Procure estacionamento',
        search_poi: 'Pesquisar PI',
        include_plafond: 'Incluir limite de motorista no cálculo da rota',
        include_plafond_near_vehicles:
            'Encontre veículos com motoristas com comprometimento suficiente para chegar ao local pesquisado',
        violated_plafond: 'A rota selecionada viola o limite do motorista'
    },
    sharedlinks: {
        back_to_links: 'Volte para os links',
        create_link: 'Criar links',
        update_link: 'Link de atualização',
        edit_link: 'Editar link',
        update_link_expire_date: 'Atualizar data de expiração',
        delete_link: 'Excluir link',
        delete_link_confirm: 'Excluir o link selecionado?',
        all_your_shared_links: 'Todos os seus links compartilhados',
        no_shared_links: 'Você ainda não compartilhou nenhum veículo',
        vehicles_link: 'Links para veículos',
        select_share_vehicles: 'Selecione veículos para compartilhar',
        select_start_expire_date: 'Selecione a data de início e expiração',
        created_link_error: 'Erro ao criar link',
        created_link_success: 'Link criado com sucesso',
        updated_link_error: 'Erro ao atualizar o link',
        updated_link_success: 'Link atualizado com sucesso',
        service_description:
            'Compartilhe este link para mostrar o rastreamento dos veículos selecionados',
        link_preview: 'Visualização do link',
        share_single_vehicle: 'Compartilhe a localização deste veículo',
        track_vehicle_position: 'Rastreie a localização dos veículos selecionados',
        start_date_warning: 'Não é possível alterar a data de início',
        start_date_condition: 'Se quiser alterar a data de início, você precisa criar um novo link',
        expire_date_description:
            'Indica uma data de expiração, após a qual este link não estará mais disponível',
        start_expire_date_description:
            'Indique uma data inicial, a partir da qual este link estará disponível, e uma data de expiração, após a qual este link não estará mais disponível:'
    },
    viewsharedlink: {
        invalid_date_range: 'O período selecionado não é válido para este link',
        too_early_link: 'O link que você está tentando acessar ainda não está disponível',
        too_late_link: 'O link que você está tentando acessar não existe mais',
        show_route: 'Mostrar rota'
    },
    tags: {
        manage_tags: 'Gerenciar tags',
        add_tag: 'Adicionar tags',
        add_tag_placeholder: 'Insira o nome da etiqueta',
        add_tag_error: 'Erro ao adicionar tag',
        add_tag_success: 'Tag adicionada com sucesso',
        delete_tag: 'Excluir tags',
        delete_tag_confirm: 'Excluir a tag selecionada?',
        delete_tag_error: 'Erro ao excluir a tag',
        delete_tag_success: 'Tag excluída com sucesso',
        update_tag: 'Atualizar tags',
        update_tag_error: 'Erro ao atualizar a tag',
        update_tag_success: 'Tag atualizada com sucesso',
        all_your_tags: 'Todas as suas tags',
        no_tags: 'Você ainda não criou nenhuma tag',
        tag_name: 'Nome da etiqueta'
    },
    manage_poi: {
        title: 'Gerenciar POIs',
        your_pois: 'Seus POIs',
        add_poi: 'Adicionar PI',
        no_pois: 'Você ainda não criou nenhum POI',
        fields: {
            name: 'Primeiro nome',
            type: 'Cara',
            color: 'Cor',
            note: 'Observação',
            address: 'Endereço de pesquisa',
            latitude: 'Latitude',
            longitude: 'Longitude',
            radius: 'Raio de pesquisa',
            radius_description:
                'Para ativar a delimitação geográfica, defina um valor maior que zero'
        },
        types_poi: {
            all: 'Todos',
            headquarter: 'Site',
            truck_washes: 'Lavagens de caminhões',
            blacklist: 'Lista negra',
            end_of_the_line: 'Término',
            customers: 'Clientes',
            stops: 'Parar',
            suppliers: 'Provedores',
            interports: 'Interportações',
            markets: 'Mercados',
            large_square: 'Quadrados',
            poi_distributors: 'ENTÃO Distribuidores',
            poi_workshops: 'ENTÃO Oficinas',
            harbors: 'Portas',
            restaurants: 'Restaurantes',
            stations: 'Estações'
        },
        messages: {
            add_poi_error: 'Erro ao adicionar POI',
            add_poi_success: 'ENTÃO adicionado com sucesso',
            update_poi_error: 'Erro ao atualizar o POI',
            update_poi_success: 'ENTÃO atualizado com sucesso',
            delete_poi_error: 'Erro ao excluir POI',
            delete_poi_success: 'ENTÃO excluído com sucesso'
        }
    },
    vehicles_cross: {
        title: 'Passagem de veículo',
        fields: {
            address: 'Endereço',
            radius: 'raio',
            time_interval: 'Espaço de tempo',
            time_interval_description: 'Selecione um intervalo de tempo máximo de 24 horas'
        },
        messages: {
            max_time_interval: 'O intervalo de tempo máximo é de 24 horas',
            error_searching: 'Ocorreu um erro ao procurar veículos',
            searching: 'Pesquisa em andamento...',
            long_loading: 'A pesquisa pode demorar alguns minutos, aguarde...',
            no_vehicles_cross: 'Nenhum veículo encontrado'
        }
    },
    plafonds: {
        session: 'Pausa de sessão',
        break: 'Intervalo',
        drive_occurring: 'Está ocorrendo um Período de Condução:',
        drive_max_label: 'Tempo máximo de condução',
        drive_max_value: '4h 30m',
        drive_done: 'Condução realizada',
        session_title: 'Informações sobre a Pausa Fracionada',
        session_tooltip: 'É possível fazer uma Pausa Fracionada',
        session_done: 'Foi detectada uma pausa de pelo menos 15 minutos.',
        session_info: 'Primeira parte de uma Pausa Fracionada já realizada',
        session_descr:
            "Lembramos que, de acordo com as normas vigentes, é possível fazer uma pausa de 15'+30' ou 15'+15'+15' (dependendo do tipo de transporte realizado).",
        session_choice: 'É possível fazer pelo menos <b>30m</b> dentro dos próximos',
        brake_start: 'Início da pausa',
        brake_done: 'Pausa realizada',
        daily: 'Diária',
        weekly: 'Semanal',
        driver_in_drive: 'Você está dirigindo',
        driver_in_rest: 'Descanso residual',
        driver_in_work: 'Trabalhar',
        driver_in_available: 'Disponibilidade',
        driver_in_availability: 'Disponibilidade',
        current_activity: 'Atividade atual',
        mini_plafond: 'Mini-teto',
        last_update: 'Última atualização',
        panel_title: 'Painel de teto',
        residual_commitment: 'Compromisso residual',
        daily_driving: 'Guia diário',
        weekly_guide: 'Guia semanal',
        biweekly_guide: 'Guia quinzenal',
        last_rest: 'Último descanso semanal gozado',
        minimal_duration_next: 'Duração mínima próxima',
        top_start_until: 'Para ser iniciado por',
        reduced_weekly_rest: 'Períodos de descanso semanais reduzidos para compensar',
        time_to_compensate: 'Hora de compensar',
        time_to_compensate_until: 'Ser compensado por',
        footer_message: '* o tempo é calculado considerando os bônus residuais',
        driver_in_block: 'Limite de motorista esgotado',
        résidu_commitment: 'Compromisso residual',
        week_guide: 'Guia Hebdomadaire',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compensador'
    },
    error_pages: {
        error_403: {
            title: 'Erro 403',
            description:
                'Prezado cliente, informamos que sua conta não está configurada para acessar este serviço. Para receber informações sobre o próprio serviço',
            retry_login: 'Por favor tente logar novamente',
            contact_us: 'Contate-nos'
        }
    },
    ptv: {
        maxpermittedweight: 'Peso pesado',
        minpermittedweight: 'Peso mínimo',
        zonename: 'Área',
        unauthorizedtype: 'Código não autorizado',
        maxemissionstandardeu: 'Classe de emissão',
        fueltype: 'Tipo de combustível',
        loadtype: 'Tipo de transporte',
        opening: 'Andar de',
        maxheight: 'altura máxima',
        maxweight: 'Peso pesado',
        maxwidth: 'Largura máxima',
        maxlength: 'Comprimento máximo',
        absolutespeed: 'Velocidade',
        hazardoustowaters:
            'Proibição de veículos que transportam mercadorias perigosas para a água',
        hazardousgoods: 'Proibição de veículos com mercadorias perigosas',
        combustibles: 'Proibição de veículos transportando combustível',
        maxaxleload: 'Peso máximo nos eixos',
        totalpermittedweight: 'Peso pesado',
        tunnelrestriction: 'Código de restrição de tunelamento',
        kingpintorearaxle: 'Distância do pino mestre ao eixo',
        numberofaxles: 'Número de eixos',
        length: 'Trecho',
        delay: 'Atraso',
        message: {
            text: 'Mensagem'
        }
    },
    options_interface: 'Interface de opções'
}
